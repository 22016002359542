import { useQuery } from "@tanstack/react-query";
import { getRocketLeagueStatPlayers } from ".";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";

export function useGetRlcsStatPlayers() {
  return useQuery({
    queryKey: rlcsStatQueryKeys.players,
    queryFn: getRocketLeagueStatPlayers,
  });
}

