import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Chip } from "@mui/material";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useGetEventById } from "../../../../api/metricsViewership/hooks/useGetEventById";
import { EventChannelsTable } from "./components/EventChannelsTable";
import { LinkChannelModal } from "./components/LinkChannelModal";
import { useGetEventReport } from "../../../../api/metricsViewership/hooks/useGetEventReport";
import { Event } from "../../../../api/metricsViewership/schemas/EventSchema";
import { DuplicateEventModal } from "./components/DuplicateEventModal";
import PageContainer from "@/components/Layout/PageContainer";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useRequestGenerateReport } from "@/api/metricsViewership/hooks/useRequestGenerateReport";
import { AddEditEventModal } from "../shared/components/AddEditEventModal";
import { getEventStatus } from "../shared/helpers/getEventStatus";

export const MetricsViewershipEventDetailsPage = () => {
  const { id: eventId } = useParams<{ id: string }>();

  const { data: event, isError } = useGetEventById(eventId);
  const { mutate: getReportFile, isLoading: isDownloadingReport, data: xlsxFile, reset } = useGetEventReport();
  const { mutate: generateReport, isLoading: isRequestingReport } = useRequestGenerateReport();

  const [reportName, setReportName] = useState<string>("");
  const [duplicatingEvent, setDuplicatingEvent] = useState<Event | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const primaryChannels = event?.channels.filter((c) => c.isPrimary) || [];

  useEffect(() => {
    if (!xlsxFile) {
      return;
    }

    const url = window.URL.createObjectURL(xlsxFile);
    const link = document.createElement("a");
    link.href = url;
    const reportFileName = `Viewership Stats (${reportName}).xlsx`;
    link.setAttribute("download", reportFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    reset();
  }, [xlsxFile, reset, reportName]);

  const eventStatus = event ? getEventStatus(event) : undefined;

  return (
    <div className="w-full">
      <PageContainer>
        <div className="flex w-full items-center gap-4">
          {isError && <div>Something went wrong when fetching the event</div>}
          {!isError && !event && <div>Event not found with the given id</div>}
          {event && (
            <>
              <div className="flex w-full flex-col gap-1">
                <header className="flex items-center gap-4">
                  {eventStatus && (
                    <span
                      title={eventStatus.title}
                      className={classNames("rounded p-2 uppercase", eventStatus.className, eventStatus.bgClassName)}
                    >
                      {eventStatus.status}
                    </span>
                  )}
                  <h1 className="text-heading">{event.name}</h1>
                  <div className="flex gap-1">
                    <AddEditEventModal editingEvent={event} />
                    <Button
                      onClick={() => {
                        setDuplicatingEvent(event);
                      }}
                    >
                      Duplicate
                    </Button>
                  </div>
                </header>
                <Table className="mt-4">
                  <TableBody>
                    {!primaryChannels.length && (
                      <TableRow>
                        {/* eslint-disable-next-line tailwindcss/migration-from-tailwind-2 */}
                        <TableCell className="bg-red-800 bg-opacity-20" colSpan={2}>
                          No data will be collected if there are no <strong>Primary</strong> channels assigned to the
                          event. You can assign a primary channel from the table below.
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className="w-60 border-none">From</TableCell>
                      <TableCell className="border-none">
                        {DateTime.fromISO(event.startDate).toUTC().toLocaleString(DateTime.DATETIME_FULL)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="border-none">To</TableCell>
                      <TableCell className="border-none">
                        {DateTime.fromISO(event.endDate).toUTC().toLocaleString(DateTime.DATETIME_FULL)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="border-none">Channels</TableCell>
                      <TableCell className="border-none">
                        Total {event.channels.length} channels - {primaryChannels.length} Primary
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="flex flex-col gap-2">
                <Button
                  onClick={() => {
                    setReportName(event.name);
                    getReportFile(event.id);
                  }}
                  disabled={isDownloadingReport}
                >
                  Download Report
                </Button>
                <Button
                  variant="default"
                  onClick={() => {
                    generateReport(event.id);
                  }}
                  disabled={isRequestingReport}
                >
                  Generate Report
                </Button>

                {event.lastGeneratedReport && (
                  <span>
                    Last generated: <br />
                    {DateTime.fromISO(event.lastGeneratedReport).toUTC().toLocaleString(DateTime.DATETIME_FULL)}
                  </span>
                )}
              </div>
            </>
          )}
        </div>

        <header>
          <h3 className="text-xl font-bold">Scout Settings</h3>
          <p>
            The Scout service auto-links channels to events based on keywords, game ID (Twitch only) and tournament
            data.
          </p>
        </header>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="w-60 border-none">Keywords</TableCell>
              <TableCell className="flex flex-wrap items-center gap-2 border-none">
                {event?.keywords.map((keyword, i) => <Chip key={i} label={keyword} size="small" className="" />)}
                {event?.keywords.length === 0 && <span>Not assigned</span>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">Twitch Game Id</TableCell>
              <TableCell className="border-none">{!event?.twitchGameId ? "Not assigned" : event.gameName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">Circuit</TableCell>
              <TableCell className="border-none">{!event?.circuitId ? "Not assigned" : event.circuitId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="border-none">Tournament</TableCell>
              <TableCell className="border-none">
                {!event?.tournamentId ? "Not assigned" : event.tournamentId}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className="flex gap-4">
          <h2 className="text-heading">Channels</h2>
          {event && <LinkChannelModal event={event} />}
        </div>

        <div className="flex w-full items-center gap-4">
          <Label htmlFor="filter">Filter</Label>
          <Input id="filter" className="w-96" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        </div>

        {event && <EventChannelsTable eventId={event.id} searchQuery={searchQuery} />}
      </PageContainer>

      {event && duplicatingEvent && (
        <DuplicateEventModal
          isOpen={!!duplicatingEvent}
          onClose={() => setDuplicatingEvent(null)}
          event={duplicatingEvent}
        />
      )}
    </div>
  );
};
