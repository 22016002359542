import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, removeChannelFromEvent } from "..";

export const useRemoveChannelFromEvent = (eventId?: string) => {
  const queryClient = useQueryClient();

  let mutationFn = undefined;
  if (eventId) {
    mutationFn = (channelId: string) => removeChannelFromEvent(eventId, channelId);
  }

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.event(eventId));
    },
  });
};
