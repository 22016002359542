import { Event } from "@/api/metricsViewership/schemas/EventSchema";
import { DateTime } from "luxon";

export const getEventStatus = (event: Event) => {
  // starts in the future
  const isUpcoming = DateTime.utc() < DateTime.fromISO(event.startDate);
  // has ended
  const isEnded = DateTime.utc() > DateTime.fromISO(event.endDate);
  // event has started
  const isOnGoing = !isUpcoming && !isEnded;
  // has active primary channels
  const isActive = event.isActive;

  return {
    status: isActive ? "Live" : isUpcoming ? "Upcoming" : isEnded ? "Ended" : "Ongoing",
    title: isActive
      ? "One or more Primary Channels are actively live streaming"
      : isUpcoming
        ? "Starts in the future"
        : isEnded
          ? "Event has ended"
          : "Event has started and is ongoing",
    className: isActive
      ? "text-sm font-bold text-red-500"
      : isUpcoming
        ? "text-green-500"
        : isEnded
          ? "text-gray-500"
          : // Ongoing
            "text-sm font-bold text-green-500",
    bgClassName: isActive
      ? "bg-red-800 bg-opacity-20 hover:bg-red-300/10"
      : isOnGoing
        ? "bg-green-800 bg-opacity-20 hover:bg-green-300/10"
        : "",
  };
};
