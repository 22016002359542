import {
  Archive,
  BarChart,
  BellRing,
  BugIcon,
  CassetteTape,
  Code,
  LucideIcon,
  PillBottle,
  ReplaceAll,
  Trophy,
  TvMinimal,
  Users,
  Volleyball,
  Palette,
  FlagIcon,
} from "lucide-react";
import { GameDefinition, PermissionDefinition } from "@/config";

export type RouteDefinition = {
  path?: string;
  title: string;
  icon?: LucideIcon;
  requiredPermission?: PermissionDefinition;
  isActive?: boolean;
  games?: GameDefinition[];
  items?: {
    path: string;
    title: string;
    requiredPermission?: PermissionDefinition;
    games?: GameDefinition[];
  }[];
};

export const routes: RouteDefinition[] = [
  {
    title: "Users",
    icon: Users,
    isActive: true,
    items: [
      {
        path: "/users/management",
        title: "Management",
        requiredPermission: "USERS_MANAGEMENT",
      },
      {
        path: "/users/roles",
        title: "Roles",
        requiredPermission: "ROLES_MANAGEMENT",
      },
    ],
  },
  {
    title: "Live",
    icon: TvMinimal,
    items: [
      {
        path: "/live/moderation",
        title: "Moderation",
        requiredPermission: "CHAT_MODERATION",
      },
      {
        path: "/live/moderation-ai",
        title: "Moderation (AI)",
        requiredPermission: "CHAT_MODERATION",
      },
      {
        path: "/live/chat-triggers",
        title: "Chat Triggers",
        requiredPermission: "CHAT_MODERATION",
      },
      {
        path: "/live/broadcasts",
        title: "Broadcasts",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/streams",
        title: "Streams",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/emulation",
        title: "Emulation",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/broadcast-ads",
        title: "Ads",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/transcriptions",
        title: "Transcriptions",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "/live/notifications",
        title: "Notifications",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
    ],
  },

  {
    title: "Play",
    icon: Trophy,
    items: [
      {
        path: "/pickems",
        title: "Pickems",
        requiredPermission: "PICKEMS_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/fantasy",
        title: "Fantasy",
        requiredPermission: "PICKEMS_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/csguessr",
        title: "CS Guessr",
        requiredPermission: "CSGUESSR_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/csquiz",
        title: "CS Quiz",
        requiredPermission: "CSQUIZ_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/counter-strikle",
        title: "Counter Strikle",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
      {
        path: "/dotle",
        title: "Dotle",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["dota"],
      },
    ],
  },
  {
    title: "Items",
    icon: ReplaceAll,
    items: [
      {
        path: "/avatars",
        title: "Avatars",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/emojis",
        title: "Emojis",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
    ],
  },
  {
    title: "Points",
    icon: PillBottle,
    items: [
      {
        path: "/points/products",
        title: "Products",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/points/collections",
        title: "Case collections",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/points/delivery",
        title: "Delivery",
        requiredPermission: "CONTENT_MANAGEMENT",
      },
      {
        path: "/points/grant",
        title: "Grant",
        requiredPermission: "USERS_MANAGEMENT",
      },
    ],
  },
  {
    title: "Tournament Organization",
    icon: Trophy,
    items: [
      {
        title: "Circuits",
        path: "/to/circuits/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Tournaments",
        path: "/to/tournaments/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Players",
        path: "/to/players/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Teams",
        path: "/to/teams/:gameId",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs", "dota"],
      },
      {
        title: "Awards",
        path: "/to/awards",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
      {
        title: "rl Ranking",
        path: "/to/rl-rankings",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["rl"],
      },
      {
        title: "Statistics",
        path: "/to/rl-statistics",
        requiredPermission: "RL_STATISTICS_MANAGEMENT",
        games: ["rl"],
      },
    ],
  },
  {
    path: "/content",
    title: "Content",
    icon: CassetteTape,
    requiredPermission: "CONTENT_MANAGEMENT",
    items: [
      {
        path: "/content",
        title: "Clips",
        requiredPermission: "CONTENT_MANAGEMENT",
        games: ["cs"],
      },
      {
        title: "Clip Curator",
        requiredPermission: "CLIPS_CURATOR_MANAGEMENT",
        path: "/content/clip-curator",
        games: ["cs"],
      },
    ],
  },
  {
    title: "Viewership",
    icon: BarChart,
    items: [
      {
        path: "metrics/viewership/events",
        title: "Events",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
      {
        path: "metrics/viewership/channels",
        title: "Channels",
        requiredPermission: "BROADCAST_MANAGEMENT",
      },
    ],
  },
  {
    path: "/notifications",
    title: "Notifications",
    icon: BellRing,
    requiredPermission: "TOURNAMENTS_MANAGEMENT",
  },
  {
    title: "Community Tournaments",
    icon: Volleyball,
    games: ["cs"],
    items: [
      {
        title: "Circuits",
        path: "/community-tournaments/circuits",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
      {
        title: "Tournaments",
        path: "/community-tournaments/tournaments",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["cs"],
      },
    ],
  },
  {
    title: "Archive",
    icon: Archive,
    items: [
      {
        path: "/statistics/rl/replays",
        title: "Statistics",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["rl"],
      },
    ],
  },

  {
    path: "/issues",
    title: "Issues",
    icon: BugIcon,
    requiredPermission: "TOURNAMENTS_MANAGEMENT",
    items: [
      {
        path: "/issues/tournaments",
        title: "Tournaments",
        requiredPermission: "TOURNAMENTS_MANAGEMENT",
        games: ["dota"],
      },
    ],
  },
  {
    title: "Creative",
    icon: Palette,
    items: [
      {
        path: "/mediakit",
        title: "Media Kit",
        requiredPermission: "BACKOFFICE_ACCESS",
      },
    ],
  },
  {
    title: "Monitoring",
    icon: FlagIcon,
    items: [
      {
        path: "/monitoring/pirate-streams",
        title: "Pirate Streams",
        requiredPermission: "MONITORING_PIRATE_STREAMS",
      },
    ],
  },
  // Please contain this at the lowest nav item.
  {
    title: "Developer Tools",
    icon: Code,
    items: [
      {
        title: "Auditlogs",
        path: "/developer/auditlogs",
        requiredPermission: "USERS_MANAGEMENT",
      },
      {
        title: "Monitoring SNS",
        path: "/developer/monitoring/sns",
        requiredPermission: "USERS_MANAGEMENT",
      },
    ],
  },
];

export const pathGames = routes.reduce(
  (acc, route) => {
    if (route.path && route.games) {
      for (const game of route.games) {
        acc[route.path] = game;
      }
    }

    if (route.items) {
      for (const item of route.items) {
        if (item.path && item.games) {
          for (const game of item.games) {
            acc[item.path] = game;
          }
        }
      }
    }
    return acc;
  },
  {} as Record<string, GameDefinition>,
);

export const allPaths = routes.flatMap((route) => [route.path, ...(route.items?.map((item) => item.path) || [])]);
