import { AxiosError } from "axios";
import { z } from "zod";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { useCreateChannel } from "@/api/metricsViewership/hooks/useCreateChannel";
import { Channel } from "@/api/metricsViewership/schemas/ChannelSchema";
import { PlatformFilter } from "./PlatformFilter";

export const CreateChannelModal = () => {
  const { mutate: createChannel, isLoading } = useCreateChannel();

  const [open, setOpen] = useState(false);
  const [channelName, setChannelName] = useState<string>("");
  const [channelSource, setChannelSource] = useState<Channel["channelSource"]>("twitch");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validationErrors = [];
  if (["youtube", "tiktok"].includes(channelSource) && channelName.length > 0 && !channelName.startsWith("@")) {
    validationErrors.push("Channel name must start with '@'");
  }
  if (["twitch", "kick", "blasttv"].includes(channelSource) && channelName.length > 0 && channelName.startsWith("@")) {
    validationErrors.push("Channel name can not start with '@'");
  }

  const createChannelAndClose = () => {
    if (!channelName || !channelSource) {
      return;
    }

    setErrorMessage(null);

    createChannel(
      {
        channelName,
        channelSource,
      },
      {
        onSuccess: () => setOpen(false),
        onError: (error) => {
          let message: string | undefined;
          if (error instanceof AxiosError) {
            const parsed = z
              .object({
                message: z.string(),
              })
              .safeParse(error.response?.data);

            message = parsed.success ? parsed.data.message : undefined;
          }

          setErrorMessage(message ?? "An error occurred. Check console for more details.");
        },
      },
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>Create Channel</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Channel</DialogTitle>
        </DialogHeader>
        <Separator />
        <div className="grid gap-4">
          <PlatformFilter channelSource={channelSource} setChannelSource={setChannelSource} />
          <div className="space-y-2">
            <Label htmlFor="name">
              Name <span className="text-red-500">*</span>
            </Label>
            <Input
              id="channelName"
              placeholder="Enter name here"
              required
              value={channelName}
              onChange={(e) => setChannelName(e.target.value)}
            />
            {validationErrors.map((error) => (
              <div key={error} className="min-h-6 text-red-500 font-style-b2-body-copy">
                {error}
              </div>
            ))}
          </div>
        </div>

        <div className="min-h-6 text-red-500">{errorMessage ?? ""}</div>

        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={createChannelAndClose} disabled={isLoading}>
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
