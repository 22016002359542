import { useGetRlcsSchedules } from "@/api/rocketLeagueStatistics/getRlcsSchedules/useGetRlcsSchedules";
import { useDeleteRlcsSchedule } from "@/api/rocketLeagueStatistics/deleteRlcsSchedule/useDeleteRlcsSchedule";
import { Schedule } from "@/api/rocketLeagueStatistics/types/schedule";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useState } from "react";
import ScheduleModal from "./components/ScheduleModal";
import { Badge } from "@/components/ui/badge";
import { getBallChasingGroupPath } from "@/routes/RocketLeagueStatistics/components/Schedules/helpers/ballChasingPathHelpers";
import { ConfirmationModal } from "@/shared/components/ConfirmationModal";

const tableHeaders: {
  id: string;
  label: string;
  align?: "left" | "center" | "right";
}[] = [
  { id: "name", label: "Name" },
  { id: "path", label: "Path" },
  { id: "status", label: "Status" },
  { id: "startTime", label: "Start Time" },
  { id: "endTime", label: "End Time" },
  { id: "createdAt", label: "Created" },
  { id: "actions", label: "Actions", align: "center" },
];

export const SchedulesTable = () => {
  const { data: schedules, isLoading } = useGetRlcsSchedules();
  const { mutate: removeSchedule } = useDeleteRlcsSchedule();
  const [removeScheduleCandidate, setRemoveScheduleCandidate] = useState<string | null>(null);
  const [schedule, setSchedule] = useState<Schedule | null>(null);

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <ScheduleModal handleClose={() => setSchedule(null)} open={!!schedule} initialSchedule={schedule ?? undefined} />
      <Table>
        <TableHeader>
          <TableRow>
            {tableHeaders.map((column) => (
              <TableHead
                key={column.id}
                className={classNames({
                  "text-center": column.align === "center",
                  "text-end": column.align === "left",
                })}
              >
                {column.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {schedules?.map((schedule) => (
            <TableRow key={schedule.id}>
              <TableCell>{schedule.name}</TableCell>
              <TableCell>
                <a href={getBallChasingGroupPath(schedule.groupId)} target="_blank" rel="noreferrer">
                  {getBallChasingGroupPath(schedule.groupId)}
                </a>
              </TableCell>
              <TableCell>
                <Badge variant={schedule.isActive ? "default" : "outline"}>
                  {schedule.isActive ? "Active" : "Inactive"}
                </Badge>
              </TableCell>
              <TableCell>
                {schedule.startTime ? DateTime.fromISO(schedule.startTime).toFormat("dd LLL yyyy") : "-"}
              </TableCell>
              <TableCell>{DateTime.fromISO(schedule.endTime).toFormat("dd LLL yyyy")}</TableCell>
              <TableCell>{DateTime.fromISO(schedule.createdAt).toFormat("dd LLL yyyy")}</TableCell>
              <TableCell className="text-center">
                <Button variant="outline" onClick={() => setSchedule(schedule)}>
                  Edit
                </Button>{" "}
                <Button
                  onClick={() => setRemoveScheduleCandidate(schedule.id)}
                  className="bg-red-600 text-white hover:bg-red-400"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationModal
        isOpen={!!removeScheduleCandidate}
        description="Are you sure you want to remove this schedule?"
        title="Remove schedule"
        onClose={() => setRemoveScheduleCandidate(null)}
        onConfirm={() => {
          if (!removeScheduleCandidate) return;
          removeSchedule(removeScheduleCandidate);
          setRemoveScheduleCandidate(null);
        }}
      />
    </div>
  );
};
