import { Label } from "@/components/ui/label";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Channel, ChannelSource } from "@/api/metricsViewership/schemas/ChannelSchema";

export const PlatformFilter = ({
  channelSource,
  setChannelSource,
}: {
  channelSource: ChannelSource;
  setChannelSource: (value: ChannelSource) => void;
}) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="name">Platform</Label>
      <ToggleGroup
        type="single"
        variant="outline"
        value={channelSource}
        onValueChange={(value) => setChannelSource(value as Channel["channelSource"])}
      >
        {["Twitch", "YouTube", "Tiktok", "Kick", "BLAST TV"].map((source) => (
          <ToggleGroupItem key={source} value={source.toLowerCase().replaceAll(" ", "")} className="grow">
            {source}
          </ToggleGroupItem>
        ))}
      </ToggleGroup>
    </div>
  );
};
