import { useMemo, useState } from "react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { ConfirmationModal } from "@/shared/components/ConfirmationModal";
import { Input } from "@/components/ui/input";
import { Trash2Icon } from "lucide-react";
import { useSaveRlcsStatPlayer } from "@/api/rocketLeagueStatistics/postRlcsStatPlayer/useSaveRlcsStatPlayer";
import { useDeletePlayerGroup } from "@/api/rocketLeagueStatistics/deleteRlcsPlayerGroup/useDeletePlayerGroup";

interface PlayerGroupVariation {
  id: string;
  platform: string;
  name: string;
}

export interface PlayerGroup {
  groupName: string;
  variations: PlayerGroupVariation[];
}

export function PlayerGroups({ groups }: { groups: PlayerGroup[] }) {
  const [search, setSearch] = useState("");
  const [removePlayerCandidate, setRemovePlayerCandidate] = useState<PlayerGroupVariation | null>(null);
  const [removePlayerGroupCandidate, setRemovePlayerGroupCandidate] = useState<string | null>(null);

  const filteredGroups = useMemo(() => {
    return groups.filter((group) => group.groupName.toLowerCase().includes(search.toLowerCase()));
  }, [groups, search]);

  const { mutate: saveRlcsStatPlayer } = useSaveRlcsStatPlayer();

  const removeTeamFromGroup = (player: PlayerGroupVariation) => {
    saveRlcsStatPlayer({
      id: player.id,
      platform: player.platform,
      playerDisplayName: null,
    });
  };

  const { mutate: deletePlayerGroup } = useDeletePlayerGroup();

  return (
    <div className="flex w-96 flex-col gap-3 rounded border-l border-gray-300/10 bg-zinc-900/70 p-2">
      <div className="flex scroll-m-20 items-center gap-3 text-sm font-semibold tracking-tight">
        <div className="w-full">Player Groups</div>
        <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="">
        <Accordion type="multiple" className="rounded">
          {filteredGroups.map((group) => (
            <AccordionItem value={group.groupName} key={group.groupName}>
              <AccordionTrigger className="relative rounded bg-zinc-900 px-1">{group.groupName}</AccordionTrigger>
              <AccordionContent className="flex flex-col gap-2 p-2">
                <Button
                  variant="destructive"
                  size="icon"
                  onClick={() => setRemovePlayerGroupCandidate(group.groupName)}
                >
                  <Trash2Icon className="size-2" />
                </Button>
                <div className="flex flex-wrap gap-2 border-t border-gray-300/10 pt-2">
                  {group.variations.map((variation) => (
                    <Badge className="flex w-fit gap-2" key={variation.id + variation.platform}>
                      {variation.name}{" "}
                      <Trash2Icon
                        onClick={() => setRemovePlayerCandidate(variation)}
                        className="size-3 cursor-pointer"
                      />
                    </Badge>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
          {filteredGroups.length === 0 && <div className="h-full text-center text-xs">No groups found</div>}
        </Accordion>
      </div>
      <ConfirmationModal
        isOpen={!!removePlayerCandidate}
        description="Are you sure you want to remove this player from the group?"
        title="Remove player"
        onClose={() => setRemovePlayerCandidate(null)}
        onConfirm={() => {
          if (!removePlayerCandidate) return;
          removeTeamFromGroup(removePlayerCandidate);
          setRemovePlayerCandidate(null);
        }}
      />
      <ConfirmationModal
        isOpen={!!removePlayerGroupCandidate}
        description={
          <>
            Are you sure you want to remove this group?
            <br />
            All the players attached to this group will become ungrouped.
          </>
        }
        title="Remove group"
        onClose={() => setRemovePlayerGroupCandidate(null)}
        onConfirm={() => {
          if (!removePlayerGroupCandidate) return;
          deletePlayerGroup({ displayName: removePlayerGroupCandidate });
          setRemovePlayerGroupCandidate(null);
        }}
      />
    </div>
  );
}
