import { ChannelSource } from "../../../../../api/metricsViewership/schemas/ChannelSchema";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useRemoveChannelFromEvent } from "@/api/metricsViewership/hooks/useRemoveChannelFromEvent";

export const UnlinkChannelModal = ({
  eventId,
  channelId,
  channelName,
  channelSource,
}: {
  eventId: string;
  channelId: string;
  channelName?: string;
  channelSource?: ChannelSource;
}) => {
  const [open, setOpen] = useState(false);
  const { mutate: removeChannelFromEvent, isLoading: isSubmitting } = useRemoveChannelFromEvent(eventId);

  const saveAndClose = () => {
    removeChannelFromEvent(channelId);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" title="Permanently Unlink this Channel from this Event">
          Unlink
        </Button>
      </DialogTrigger>

      <DialogContent className="w-full max-w-4xl">
        <DialogHeader>
          <DialogTitle>Unlink channel</DialogTitle>
          <DialogDescription>
            Unlinked channel will no longer be tracked for this event, and will not be shown in reports.
          </DialogDescription>
        </DialogHeader>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <PlatformIcon platform={channelSource} />
              </TableCell>
              <TableCell>{channelName}</TableCell>
              <TableCell>{channelId}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button onClick={saveAndClose}>Unlink</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
