import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, addChannelToEvent } from "..";

export const useAddChannelToEvent = (eventId?: string) => {
  const queryClient = useQueryClient();

  let mutationFn = undefined;

  if (eventId) {
    type Channel = Parameters<typeof addChannelToEvent>[1];
    mutationFn = (channel: Channel) => addChannelToEvent(eventId, channel);
  }

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.event(eventId));
    },
  });
};
