import { useMemo, useState } from "react";

import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useGetRlcsStatPlayers } from "@/api/rocketLeagueStatistics/getRlcsStatPlayers/useGetRlcsStatPlayers";
import { PlayerGroup, PlayerGroups } from "./components/PlayerGroups";
import { ComboboxRlPlayers } from "./components/ComboboxRlPlayers";
import CreatePlayerGroupModal from "./components/CreatePlayerGroupModal";
import { useSaveRlcsStatPlayer } from "@/api/rocketLeagueStatistics/postRlcsStatPlayer/useSaveRlcsStatPlayer";
import { RocketLeaguePlayer } from "@/api/rocketLeagueStatistics/getRlcsStatPlayers";

export function PlayersGrouping() {
  const [isOpen, setIsOpen] = useState(false);

  const { data: players } = useGetRlcsStatPlayers();
  const [tempChosenPlayer, setTempChosenPlayer] = useState<RocketLeaguePlayer | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { mutate: saveRlcsStatPlayer } = useSaveRlcsStatPlayer();

  const ungroupedPlayers = useMemo(() => {
    return (
      players?.filter(
        (player) =>
          !player.playerDisplayName &&
          (player.playerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            player.nameHistory.some((name) => name.toLowerCase().includes(searchTerm.toLowerCase()))),
      ) || []
    );
  }, [players, searchTerm]);

  const playerGroups = useMemo(() => {
    const data = players?.reduce(
      (acc, player) => {
        if (!player.playerDisplayName) return acc;
        acc[player.playerDisplayName] ??= { groupName: player.playerDisplayName, variations: [] };
        acc[player.playerDisplayName].variations.push({
          id: player.id,
          platform: player.platform,
          name: player.playerName,
        });
        return acc;
      },
      {} as Record<string, PlayerGroup>,
    );
    return Object.values(data ?? {});
  }, [players]);

  const groupsOptions: { value: string; label: string }[] = playerGroups.map((group) => ({
    value: group.groupName,
    label: group.groupName,
  }));

  return (
    <div className="flex gap-3">
      <div className="flex w-full rounded border-gray-300/10 bg-zinc-900/70 p-2.5">
        <CreatePlayerGroupModal
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          handleSubmit={(displayName) => {
            if (!tempChosenPlayer) return;
            saveRlcsStatPlayer({
              id: tempChosenPlayer.id,
              platform: tempChosenPlayer.platform,
              playerDisplayName: displayName,
            });
            setTempChosenPlayer(null);
            setIsOpen(false);
          }}
        />
        <div className="flex w-full flex-col gap-3">
          <div className="scroll-m-20 text-sm font-semibold tracking-tight">
            <div>Missing players</div>
          </div>

          <Input
            placeholder="Search..."
            className="w-96"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Table>
            <TableHeader>
              <TableRow className="text-xs">
                <TableHead>Player Name</TableHead>
                <TableHead>Also seen as</TableHead>
                <TableHead className="w-56">Group</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {ungroupedPlayers.map((player) => (
                <TableRow key={player.id} className="text-xs">
                  <TableCell>{player.playerName}</TableCell>
                  <TableCell>
                    {player.nameHistory.map((name, index) => (
                      <span key={`${name}-${player.id}`}>{(index ? ", " : "") + name}</span>
                    ))}
                  </TableCell>
                  <TableCell>
                    <ComboboxRlPlayers
                      onAddNew={() => {
                        setTempChosenPlayer(player);
                        setIsOpen(true);
                      }}
                      options={groupsOptions}
                      value={""}
                      onChange={(playerDisplayName) => {
                        saveRlcsStatPlayer({
                          id: player.id,
                          platform: player.platform,
                          playerDisplayName: playerDisplayName,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <PlayerGroups groups={playerGroups} />
    </div>
  );
}
