import { ChevronsUpDown } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "@/components/ui/sidebar";
import { useBackoffice } from "@/providers/BackofficeProvider/useState";
import { GameDefinition } from "../util/types/Game";

export function GameSwitcher({ games }: { games: GameDefinition[] }) {
  const { isMobile } = useSidebar();
  const { currentGame, setCurrentGame } = useBackoffice();
  const activeGame = games.find((game) => game.value === currentGame);
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex w-8 items-center justify-center rounded-lg text-sidebar-primary-foreground">
                <img src={activeGame?.image ?? ""} alt={activeGame?.name ?? ""} className="w-8 rounded-sm" />
              </div>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{activeGame?.name ?? ""}</span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">Teams</DropdownMenuLabel>
            {games.map((game, index) => (
              <DropdownMenuItem key={game.name} onClick={() => setCurrentGame(game.value)} className="gap-2 p-2">
                <div className="flex size-6 items-center justify-center rounded-sm border">
                  <img src={game.image} alt={game.name} className="w-6 shrink-0 rounded-sm" />
                </div>
                {game.name}
                <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
