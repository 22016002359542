import { useQuery } from "@tanstack/react-query";
import { getChannelList, queryKeys } from "..";

export const useGetChannelsList = () => {
  return useQuery({
    queryKey: queryKeys.channelList(),
    queryFn: getChannelList,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};
