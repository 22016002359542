import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ArrowUpDown } from "lucide-react";
import classNames from "classnames";
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { PlatformIcon } from "@/routes/MetricsViewership/routes/shared/components/PlatformIcon";
import { useToggleChannelExcludedStatus } from "@/api/metricsViewership/hooks/useToggleChannelExcludedStatus";
import { ChannelSource } from "@/api/metricsViewership/schemas/ChannelSchema";
import { useGetEventById } from "@/api/metricsViewership/hooks/useGetEventById";
import { useToggleChannelPrimaryStatus } from "@/api/metricsViewership/hooks/useToggleChannelPrimaryStatus";
import { UnlinkChannelModal } from "./UnlinkChannelModal";

type EventChannelRow = {
  id: string;
  channelSource?: ChannelSource;
  channelName?: string;
  isPrimary?: boolean;
  scoutedAt?: string;
  excluded?: boolean;
};

export const EventChannelsTable = ({ eventId, searchQuery }: { eventId: string; searchQuery: string }) => {
  const { mutate: toggleChannelExcluded } = useToggleChannelExcludedStatus(eventId);
  const { mutate: toggleChannelPrimaryStatus } = useToggleChannelPrimaryStatus(eventId);

  const { data: event } = useGetEventById(eventId);

  const filteredChannels = useMemo(() => {
    if (!event) return [];
    return event.channels.filter((channel) => channel.channelName?.match(new RegExp(`${searchQuery}`, "i")));
  }, [searchQuery, event]);

  const columns: ColumnDef<EventChannelRow>[] = [
    {
      accessorKey: "channelSource",
      sortingFn: (rowA, rowB) => {
        const sourceA = rowA.original.channelSource ?? "";
        const sourceB = rowB.original.channelSource ?? "";
        const sourceComparison = sourceA.localeCompare(sourceB);
        if (sourceComparison !== 0) return sourceComparison;

        // secondary sort by channel name
        const nameA = rowA.original.channelName ?? "";
        const nameB = rowB.original.channelName ?? "";
        return nameA.localeCompare(nameB);
      },
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Platform
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      cell: ({ row }) => <PlatformIcon platform={row.original.channelSource} />,
    },
    {
      accessorKey: "channelName",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Name
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const channel = row.original;
        return channel.channelSource === "youtube" ? (
          <Link
            className="cursor-pointer underline"
            target="_blank"
            to={`https://www.youtube.com/${channel.channelName}`}
          >
            {channel.channelName}
          </Link>
        ) : channel.channelSource === "twitch" ? (
          <Link
            className="cursor-pointer underline"
            target="_blank"
            to={`https://www.twitch.tv/${channel.channelName}`}
          >
            {channel.channelName}
          </Link>
        ) : channel.channelSource === "kick" ? (
          <Link className="cursor-pointer underline" target="_blank" to={`https://www.kick.com/${channel.channelName}`}>
            {channel.channelName}
          </Link>
        ) : channel.channelSource === "tiktok" ? (
          <Link
            className="cursor-pointer underline"
            target="_blank"
            to={`https://www.tiktok.com/${channel.channelName}/live`}
          >
            {channel.channelName}
          </Link>
        ) : channel.channelSource === "blasttv" ? (
          <Link className="cursor-pointer underline" target="_blank" to={`https://www.blast.tv`}>
            {channel.channelName}
          </Link>
        ) : (
          channel.channelName
        );
      },
    },
    {
      accessorKey: "scoutedAt",
      sortingFn: "datetime",
      cell: ({ row }) => {
        const channel = row.original;
        return channel.scoutedAt && new Date(channel.scoutedAt).toLocaleString();
      },
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Auto Linked
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: "isPrimary",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Primary
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        return row.original.isPrimary ? "Primary" : "";
      },
    },
    {
      accessorKey: "excluded",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Excluded
            <ArrowUpDown className="ml-2 size-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        return <div>{row.original.excluded ? "Excluded" : ""}</div>;
      },
    },
  ];

  const table = useReactTable({
    data: filteredChannels,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: "channelSource",
          desc: false,
        },
      ],
    },
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  className={classNames({
                    "w-32": header.id === "channelSource",
                  })}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              );
            })}
            <TableHead className="flex items-center justify-center">Actions</TableHead>
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            key={row.id}
            data-state={row.getIsSelected() && "selected"}
            className={classNames({
              "bg-red-800 bg-opacity-10 hover:bg-red-300/10": row.original.excluded,
              "bg-blue-800 bg-opacity-20 hover:bg-blue-300/10": row.original.isPrimary,
            })}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
            <TableCell key="actions">
              <div className="flex gap-2">
                <UnlinkChannelModal
                  eventId={eventId}
                  channelId={row.original.id}
                  channelName={row.original.channelName}
                  channelSource={row.original.channelSource}
                />

                <Button
                  variant="ghost"
                  onClick={() => toggleChannelExcluded(row.original.id)}
                  title={row.original.excluded ? "Include in reporting" : "Exclude from reporting"}
                >
                  {row.original.excluded ? "Include" : "Exclude"}
                </Button>

                {row.original.channelSource === "twitch" && (
                  <Button
                    variant="ghost"
                    onClick={() => toggleChannelPrimaryStatus(row.original.id)}
                    title="Toggle Primary status"
                  >
                    Primary
                  </Button>
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
