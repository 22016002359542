import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useState } from "react";

export default function CreatePlayerGroupModal({
  open,
  handleClose,
  handleSubmit,
}: {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (displayName: string) => void;
}) {
  const [displayName, setDisplayName] = useState("");

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && handleClose()} aria-describedby="modal-description">
      <DialogContent id="modal-description">
        <DialogHeader>
          <DialogTitle>Add player group</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <Input placeholder="Player group name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
        </div>
        <div className="flex flex-col gap-2">
          <DialogFooter>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={() => handleSubmit(displayName)}>Create</Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
