import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, toggleChannelPrimaryStatus } from "..";

export const useToggleChannelPrimaryStatus = (eventId?: string) => {
  const queryClient = useQueryClient();

  let mutationFn = undefined;
  if (eventId) {
    mutationFn = (channelId: string) => toggleChannelPrimaryStatus(eventId, channelId);
  }

  return useMutation({
    mutationFn,
    onSuccess: () => {
      void queryClient.invalidateQueries(queryKeys.event(eventId));
    },
  });
};
