import { z } from "zod";

export const environment = import.meta.env.VITE_ENVIRONMENT as "production" | "development" | "local" | undefined;

export const baseApiURL = import.meta.env.VITE_BASE_API_URL as string;
if (!baseApiURL) {
  throw new Error("VITE_BASE_API_URL is not defined");
}

export const baseDataURL = import.meta.env.VITE_BASE_DATA_URL as string;
if (!baseDataURL) {
  throw new Error("VITE_BASE_DATA_URL is not defined");
}

export const avatarVersion = import.meta.env.VITE_AVATAR_VERSION as string;
if (!avatarVersion) {
  throw new Error("VITE_AVATAR_VERSION is not defined");
}

export const emojiVersion = import.meta.env.VITE_EMOJI_VERSION as string;
if (!emojiVersion) {
  throw new Error("VITE_EMOJI_VERSION is not defined");
}

export const assetsURL = import.meta.env.VITE_ASSETS_URL as string;
if (!assetsURL) {
  throw new Error("VITE_ASSETS_URL is not defined");
}

export const sanityDataset = import.meta.env.VITE_SANITY_DATASET as string;
if (!sanityDataset) {
  throw new Error("VITE_SANITY_DATASET is not defined");
}

export const flagsmithEnvironmentId = import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID as string;
if (!flagsmithEnvironmentId) {
  throw new Error("VITE_FLAGSMITH_ENVIRONMENT_ID is not defined");
}

export const timelineBaseApiURL = import.meta.env.VITE_TIMELINE_BASE_API_URL as string;
if (!timelineBaseApiURL) {
  throw new Error("VITE_TIMELINE_BASE_API_URL is not defined");
}

export const clipsAwsBaseUrl = import.meta.env.VITE_CLIPS_AWS_BASE_URL as string;
if (!timelineBaseApiURL) {
  throw new Error("VITE_TIMELINE_BASE_API_URL is not defined");
}

export const clipsCuratorBaseUrl = import.meta.env.VITE_CLIPS_CURATOR_BASE_URL as string;
if (!clipsCuratorBaseUrl) {
  throw new Error("VITE_CLIPS_CURATOR_BASE_URL is not defined");
}

export const PermissionDefinitionSchema = z.enum([
  "BACKOFFICE_ACCESS",
  "BROADCAST_MANAGEMENT",
  "CHAT_MODERATION",
  "CLIPS_CURATOR_MANAGEMENT",
  "CONTENT_MANAGEMENT",
  "CSGUESSR_MANAGEMENT",
  "CSQUIZ_MANAGEMENT",
  "MONITORING_PIRATE_STREAMS",
  "PICKEMS_MANAGEMENT",
  "ROLES_MANAGEMENT",
  "TOURNAMENTS_MANAGEMENT",
  "USERS_MANAGEMENT",
  "RL_STATISTICS_MANAGEMENT",
]);

export type PermissionDefinition = z.infer<typeof PermissionDefinitionSchema>;

export const GamesSchema = z.enum(["dota", "cs", "rl", "deadlock"]);

export type GameDefinition = z.infer<typeof GamesSchema>;
