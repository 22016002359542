import { blastApiClient } from "../../clients/blastApiClient";
import { z } from "zod";

export const RocketLeaguePlayerSchema = z.object({
  id: z.string(),
  platform: z.string(),
  playerName: z.string(),
  playerDisplayName: z.string().nullable(),
  lastSeenMatch: z.string(),
  nameHistory: z.string().array(),
});

export type RocketLeaguePlayer = z.infer<typeof RocketLeaguePlayerSchema>;

export const getRocketLeagueStatPlayers = async () => {
  const { data } = await blastApiClient.get<unknown>("/v1/rlcs-statistics/players");

  const players = RocketLeaguePlayerSchema.array().parse(data);
  return players;
};
