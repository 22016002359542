"use client";

import { ChevronRight } from "lucide-react";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useBackoffice } from "@/providers/BackofficeProvider/useState";
import { RouteDefinition } from "../util/routes";
import { useEffect, useMemo, useState } from "react";

function SidebarItem({ item }: { item: RouteDefinition }) {
  const { currentGame } = useBackoffice();
  const [isItemOpen, setIsItemOpen] = useState(false);
  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    if (item.path && pathname.includes(item.path)) {
      return true;
    }
    if (item.items) {
      return item.items.some((subItem) => pathname.includes(subItem.path ?? ""));
    }
    return false;
  }, [pathname, item.path, item.items]);

  useEffect(() => {
    if (isActive) {
      setIsItemOpen(true);
    }
  }, [isActive]);

  return (
    <Collapsible key={item.title} asChild open={isItemOpen} onOpenChange={setIsItemOpen} className="group/collapsible">
      <SidebarMenuItem>
        {item.items && item.items.length > 0 ? (
          <CollapsibleTrigger asChild>
            <SidebarMenuButton
              tooltip={item.title}
              className={classNames({
                "rounded-md bg-sidebar-accent": isActive,
              })}
            >
              {item.icon && <item.icon />}
              <span className="line-clamp-1">{item.title}</span>

              <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
            </SidebarMenuButton>
          </CollapsibleTrigger>
        ) : (
          <Link
            to={
              item.games?.includes(currentGame) ? (item.path ?? "").replace(":gameId", currentGame) : (item.path ?? "")
            }
          >
            <SidebarMenuButton
              tooltip={item.title}
              className={classNames({
                "rounded-md bg-sidebar-accent": isActive,
              })}
            >
              {item.icon && <item.icon />}
              <span className="line-clamp-1">{item.title}</span>
            </SidebarMenuButton>
          </Link>
        )}
        <CollapsibleContent className="mt-0.5">
          <SidebarMenuSub>
            {item.items?.map((subItem) => (
              <SidebarMenuSubItem
                key={subItem.title}
                className={classNames({
                  "rounded-md bg-sidebar-accent": pathname === subItem.path,
                })}
              >
                <SidebarMenuSubButton asChild>
                  <Link
                    to={
                      subItem.games?.includes(currentGame)
                        ? (subItem.path ?? "").replace(":gameId", currentGame)
                        : (subItem.path ?? "")
                    }
                  >
                    <span>{subItem.title}</span>
                  </Link>
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
}

export function NavMain({ items, title }: { items: RouteDefinition[]; title: string }) {
  return (
    <SidebarGroup>
      <SidebarGroupLabel>{title}</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => (
          <SidebarItem key={item.title} item={item} />
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
