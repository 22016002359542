import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";
import { deletePlayerGroup } from ".";

export function useDeletePlayerGroup() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePlayerGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rlcsStatQueryKeys.players,
      });
    },
  });
}
