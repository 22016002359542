import { GameDefinition } from "./types/Game";

export const games: GameDefinition[] = [
  {
    name: "Counter-Strike 2",
    image: "https://assets.blast.tv/game_icons/cs.png",
    value: "cs",
  },
  {
    name: "Dota 2",
    image: "https://assets.blast.tv/game_icons/dota2.jpeg",
    value: "dota",
  },
  {
    name: "Deadlock",
    image: "https://assets.blast.tv/game_icons/deadlock.png",
    value: "deadlock",
  },
  {
    name: "Rocket League",
    image: "https://assets.blast.tv/game_icons/rocketleague.png",
    value: "rl",
  },
];
