import { useMutation, useQueryClient } from "@tanstack/react-query";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";
import { postRlcsStatPlayer } from ".";

export function useSaveRlcsStatPlayer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postRlcsStatPlayer,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rlcsStatQueryKeys.players,
      });
    },
  });
}
