import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider/hooks/useAuth";

import { AppSidebar } from "@/components/ProtectedRoute/components/AppSidebar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { useMemo } from "react";
import { allPaths } from "./util/routes";

export function ProtectedRoute() {
  const auth = useAuth();

  const location = useLocation();

  const breadcrumbs = useMemo(() => {
    if (location.pathname === "/") {
      return [{ title: "Welcome" }];
    }
    const paths = location.pathname.split("/").filter(Boolean);
    const result: { title: string; href?: string }[] = [];

    let currentPath = "";

    paths.forEach((path) => {
      currentPath += `/${path}`;
      if (allPaths.includes(currentPath)) {
        result.push({
          title: currentPath.split("/").pop()!,
          href: currentPath,
        });
      } else {
        result.push({
          title: path,
        });
      }
    });

    return result;
  }, [location.pathname]);

  if (!auth.hasAccess) {
    return (
      <Navigate
        to={`/login?redirect_path=${encodeURIComponent(location.pathname + location.search + location.hash)}`}
        replace
      />
    );
  }

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <header className="z-10 flex h-16 shrink-0 items-center gap-2 border-b border-zinc-800 bg-zinc-900/80 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                {breadcrumbs.map((crumb, index) => (
                  <>
                    <BreadcrumbItem key={crumb.title}>
                      {index === breadcrumbs.length - 1 ? (
                        <BreadcrumbPage className="capitalize">{crumb.title}</BreadcrumbPage>
                      ) : crumb.href ? (
                        <BreadcrumbLink asChild href={crumb.href} className="capitalize">
                          <Link to={crumb.href}>{crumb.title}</Link>
                        </BreadcrumbLink>
                      ) : (
                        <BreadcrumbPage className="capitalize text-muted-foreground">{crumb.title}</BreadcrumbPage>
                      )}
                    </BreadcrumbItem>
                    {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                  </>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header>
        <Outlet />
      </SidebarInset>
    </SidebarProvider>
  );
}
