import { blastApiClient } from "../../clients/blastApiClient";

export interface PostRlcsStatPlayersData {
  id: string;
  platform: string;
  playerDisplayName: string | null;
}

export const postRlcsStatPlayer = async ({ id, platform, playerDisplayName }: PostRlcsStatPlayersData) => {
  return await blastApiClient.post<unknown>(
    `/v1/rlcs-statistics/players/display-name/${playerDisplayName?.trim() || "unknown"}`,
    {
      id,
      platform,
    },
  );
};
