import { toast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRlcsSchedule } from ".";
import { rlcsStatQueryKeys } from "../rlcsStatQueryKeys";

export const useDeleteRlcsSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteRlcsSchedule,
    onSuccess: () => {
      void queryClient.invalidateQueries(rlcsStatQueryKeys.schedules);
      toast({
        title: "Schedule deleted successfully",
        description: "The schedule has been deleted successfully",
      });
    },
    onError: () => {
      toast({
        title: "Failed to delete schedule",
        description: "The schedule could not be deleted",
        variant: "destructive",
      });
    },
  });
};
