import { useMemo, useState } from "react";

import { useGetRlcsStatTeams } from "@/api/rocketLeagueStatistics/getRLCSStatTeams/hooks/useGetRlcsStatTeams";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { ComboboxRlTeams } from "./components/ComboboxRlTeams";
import CreateTeamGroupModal from "./components/CreateTeamModal";
import { usePostRlcsStatTeam } from "@/api/rocketLeagueStatistics/postRlcsStatTeam/hooks/usePostRlcsStatTeam";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Trash2Icon } from "lucide-react";
import { useDeleteTeamGroup } from "@/api/rocketLeagueStatistics/deleteTeamGroup/hooks/useDeleteTeamGroup";
import { ConfirmationModal } from "@/shared/components/ConfirmationModal";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

interface Group {
  groupName: string;
  variations: string[];
}
export function Groups({ groups }: { groups: Group[] }) {
  const [search, setSearch] = useState("");
  const [removeTeamCandidate, setRemoveTeamCandidate] = useState<string | null>(null);
  const [removeTeamGroupCandidate, setRemoveTeamGroupCandidate] = useState<string | null>(null);

  const filteredGroups = useMemo(() => {
    return groups.filter((group) => group.groupName.toLowerCase().includes(search.toLowerCase()));
  }, [groups, search]);

  const { mutate: postRlcsStatTeam } = usePostRlcsStatTeam();

  const removeTeamFromGroup = (teamName: string) => {
    postRlcsStatTeam({
      teamName: teamName,
      teamDisplayName: null,
    });
  };

  const { mutate: deleteTeamGroup } = useDeleteTeamGroup();

  return (
    <div className="flex w-96 flex-col gap-3 rounded border-l border-gray-300/10 bg-zinc-900/70 p-2">
      <div className="flex scroll-m-20 items-center gap-3 text-sm font-semibold tracking-tight">
        <div className="w-full">Team Groups</div>
        <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="">
        <Accordion type="multiple" className="rounded">
          {filteredGroups.map((group) => (
            <AccordionItem value={group.groupName} key={group.groupName}>
              <AccordionTrigger className="relative rounded bg-zinc-900 px-1">{group.groupName}</AccordionTrigger>
              <AccordionContent className="flex flex-col gap-2 p-2">
                <Button variant="destructive" size="icon" onClick={() => setRemoveTeamGroupCandidate(group.groupName)}>
                  <Trash2Icon className="size-2" />
                </Button>
                <div className="flex flex-wrap gap-2 border-t border-gray-300/10 pt-2">
                  {group.variations.map((variation) => (
                    <Badge className="flex w-fit gap-2" key={variation}>
                      {variation}{" "}
                      <Trash2Icon onClick={() => setRemoveTeamCandidate(variation)} className="size-3 cursor-pointer" />
                    </Badge>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
          {filteredGroups.length === 0 && <div className="h-full text-center text-xs">No groups found</div>}
        </Accordion>
      </div>
      <ConfirmationModal
        isOpen={!!removeTeamCandidate}
        description="Are you sure you want to remove this team from the group?"
        title="Remove team"
        onClose={() => setRemoveTeamCandidate(null)}
        onConfirm={() => {
          if (!removeTeamCandidate) return;
          removeTeamFromGroup(removeTeamCandidate);
          setRemoveTeamCandidate(null);
        }}
      />
      <ConfirmationModal
        isOpen={!!removeTeamGroupCandidate}
        description={
          <>
            Are you sure you want to remove this group?
            <br />
            All the teams attached to this group will become ungrouped.
          </>
        }
        title="Remove group"
        onClose={() => setRemoveTeamGroupCandidate(null)}
        onConfirm={() => {
          if (!removeTeamGroupCandidate) return;
          deleteTeamGroup({ displayName: removeTeamGroupCandidate });
          setRemoveTeamGroupCandidate(null);
        }}
      />
    </div>
  );
}

export function TeamsChanger() {
  const [isOpen, setIsOpen] = useState(false);

  const { data: teams } = useGetRlcsStatTeams();
  const [tempChosenTeam, setTempChosenTeam] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { mutate: postRlcsStatTeam } = usePostRlcsStatTeam();

  const missingTeams = useMemo(() => {
    return teams?.filter((team) => !team.teamDisplayName) || [];
  }, [teams]);

  const groups = useMemo(() => {
    const data = teams?.reduce(
      (acc, team) => {
        if (!team.teamDisplayName) return acc;
        acc[team.teamDisplayName] ??= { groupName: team.teamDisplayName, variations: [] };
        acc[team.teamDisplayName].variations.push(team.teamName);
        return acc;
      },
      {} as Record<string, Group>,
    );
    return Object.values(data ?? {});
  }, [teams]);

  const groupsOptions: { value: string; label: string }[] = groups.map((group) => ({
    value: group.groupName,
    label: group.groupName,
  }));

  return (
    <div className="flex gap-3">
      <div className="flex w-full rounded border-gray-300/10 bg-zinc-900/70 p-2.5">
        <CreateTeamGroupModal
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          handleSubmit={(displayName) => {
            if (!tempChosenTeam) return;
            postRlcsStatTeam({
              teamName: tempChosenTeam,
              teamDisplayName: displayName,
            });
            setTempChosenTeam(null);
            setIsOpen(false);
          }}
        />
        <div className="flex w-full flex-col gap-3">
          <div className="scroll-m-20 text-sm font-semibold tracking-tight">
            <div>Missing teams</div>
          </div>

          <Input
            placeholder="Search..."
            className="w-96"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Table>
            <TableHeader>
              <TableRow className="text-xs">
                <TableHead>Team Name</TableHead>
                <TableHead className="w-56">Group</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {missingTeams
                .filter((team) => team.teamName.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((team) => (
                  <TableRow key={team.teamName} className="text-xs">
                    <TableCell>{team.teamName}</TableCell>
                    <TableCell>
                      <ComboboxRlTeams
                        onAddNew={() => {
                          setTempChosenTeam(team.teamName);
                          setIsOpen(true);
                        }}
                        options={groupsOptions}
                        value={""}
                        onChange={(teamDisplayName) => {
                          postRlcsStatTeam({
                            teamName: team.teamName,
                            teamDisplayName,
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <Groups groups={groups} />
    </div>
  );
}
